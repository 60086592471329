import { validator, messages } from '@helpers/validation/validator';
import { resetFormElements } from '@helpers/validation/function.validation';
import { createError, removeError, isValid } from '@helpers/validation/function.validation';
import axios from 'axios';
// import { hiddenScroll, visibleScroll, warn } from '@helpers/utils';
// import { api, generateCancelRequest } from '@helpers/axios';
// import { TEXT_ERROR_MESSAGE } from '@helpers/constants';

export default class Validation {
    constructor($form, options = {}) {
        this.$form = $form;
        this.$popupSuccess = document.getElementsByClassName('success-message')[0];
        this.$btnSubmit = $form.querySelector('[type="submit"]');
        this.$inputs =
            [...this.$form.querySelectorAll('input[required]')].map($el => {
                return {
                    name: $el.name,
                    isTouched: false,
                    isValid: null,
                    isEmpty: $el.value.trim().length ? false : true,
                    target: $el,
                };
            }) || [];
        this.options = options;
        this.requestCancel = { cancel: null };
        this.formData = null;
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handlerInput = this.handlerInput.bind(this);
        this.handlerFocusout = this.handlerFocusout.bind(this);
    }

    init() {
        this.$form.addEventListener('submit', this.handlerSubmit);
        this.$form.addEventListener('input', this.handlerInput);
        this.$form.addEventListener('focusout', this.handlerFocusout);
        this.$form.addEventListener('focusin', this.handlerFocusin);
    }

    async handlerSubmit(e) {
        e.preventDefault();
        const $form = e.target;
        if (isValid(this.$inputs)) {
            // if ($form.hasAttribute('data-wait-success')) {
            //     this.$btnSubmit.setAttribute('disabled', true);
            //     console.log('is successfully!');

            //     setTimeout(() => {
            //         const $currentPopup = this.$form.closest('.popup.js-popup-opened');
            //         this.$inputs = [...resetFormElements(this.$inputs)];
            //         this.$form.reset();
            //         this.$btnSubmit.removeAttribute('disabled');
            //         $currentPopup.classList.remove('js-popup-opened');
            //         visibleScroll();
            //         this.$popupSuccess.classList.add('js-popup-opened');
            //         hiddenScroll();
            //     }, 1e3);
            // }
            if ($form.hasAttribute('data-ajax')) {
                const { action } = $form;
                // this.$btnSubmit.setAttribute('disabled', true);
                this.formData = new FormData($form);

                try {
                    const { status, statusText } = await axios.post(action, this.formData);

                    if (status === 200 || statusText.toLowerCase() === 'ok') {
                        $form.reset();

                        this.$popupSuccess.classList.add('js-popup-opened');
                        this.$inputs = [...resetFormElements(this.$inputs)];
                        // this.$btnSubmit.removeAttribute('disabled');
                        this.formData = null;
                        setTimeout(() => {
                            this.$popupSuccess.classList.remove('js-popup-opened');
                        }, 5000);
                    }
                } catch (error) {
                    // this.$btnSubmit.removeAttribute('disabled');
                    this.formData = null;
                }
            }
        }
    }

    handlerInput(e) {
        const isTarget = ['INPUT', 'TEXTAREA'].includes(e.target.nodeName);
        if (isTarget) {
            const $target = e.target;
            const { name } = $target;
            const correctInput = this.$inputs.find($el => $el.name === name) || null;
            if (correctInput) {
                correctInput.isTouched = true;
                if (correctInput.isValid !== null) {
                    if ($target.value.trim().length) {
                        if (typeof validator[name] === 'function') {
                            if (validator[name]($target.value)) {
                                removeError($target);
                                correctInput.isValid = true;
                            } else {
                                createError($target, messages[name]);
                                correctInput.isValid = false;
                            }
                        } else if (!validator[name]) {
                            removeError($target);
                            correctInput.isValid = true;
                        }
                    } else if (!$target.value.trim().length) {
                        createError($target);
                        correctInput.isValid = false;
                    }
                }
            }
        }
    }

    handlerFocusout(e) {
        const isTarget = ['INPUT', 'TEXTAREA'].includes(e.target.nodeName);
        if (isTarget) {
            const $target = e.target;
            const { name } = $target;
            const correctInput = this.$inputs.find($el => $el.name === name) || null;
            removeError($target);
            if (correctInput && correctInput.isTouched) {
                if (typeof validator[name] === 'function' && $target.value.trim().length) {
                    if (validator[name]($target.value)) {
                        removeError($target);
                        correctInput.isValid = true;
                    } else {
                        createError($target, messages[name]);
                        correctInput.isValid = false;
                    }
                    return;
                } else if (!$target.value.trim().length) {
                    createError($target);
                    correctInput.isValid = false;
                    return;
                }
                correctInput.isValid = true;
            }
        }
    }

    destroy() {
        this.$form.removeEventListener('submit', this.handlerSubmit);
        this.$form.removeEventListener('input', this.handlerInput);
        this.$form.removeEventListener('blur', this.handlerFocusout);
    }
}
