import { EventEmitter } from '@helpers/EventEmitter';
import { resizer } from '@helpers/resizer';
import Navbar from '@components/ui/navbar/Navbar';
import Slider from '@components/UI/slider/Slider';
import Accordion from './components/ui/accordion/Accordion';
import Modal from './components/ui/modal/Modal';
import Validation from './helpers/validation/Validation';
import { hiddenScroll, visibleScroll } from './helpers/utils';

const emitter = new EventEmitter();
window.onload = function () {
    document.body.classList.add('loaded_hiding');
    window.setTimeout(function () {
        document.body.classList.add('loaded');
        document.body.classList.remove('loaded_hiding');
    }, 500);
};

// NOTE: Navbar
{
    const $navbar = document.querySelector('[data-nav]') || null;

    // const $header = document.querySelector('[data-scroll]') || null;
    // const scrollUp = 'scroll-up';
    // const scrollDown = 'scroll-down';
    // let lastScroll = 0;

    if ($navbar) {
        let navbar = null;
        const mq = window.matchMedia('(max-width: 796px)');

        const checkMq = () => {
            if (mq.matches) {
                navbar?.destroy();
                navbar = new Navbar($navbar);
                navbar.init();
            } else if (navbar !== null) {
                navbar?.destroy();
                navbar = null;
            }
        };

        checkMq();
        mq.addListener(checkMq);
    }
}

{
    const $forms = document.querySelectorAll('[data-validation]');
    if ($forms.length) {
        for (let i = 0; i < $forms.length; i++) {
            new Validation($forms[i], {}).init();
        }
    }
}

//Animation
const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const square = entry.target;

        if (entry.isIntersecting) {
            square.classList.add('start-animate');
            return; // if we added the class, exit the function
        }

        // We're not intersecting, so remove the class!
        square.classList.remove('start-animate');
    });
});

const graph = document.querySelector('.graph-animation');
const circle = document.querySelector('.circle-graph-svg');
const rect = document.querySelector('.rect-graph');

if (graph) {
    observer.observe(graph);
}

if (circle) {
    observer.observe(circle);
}

if (rect) {
    observer.observe(rect);
}

//NOTE: Accordion
{
    const $accordions = document.querySelectorAll('[data-accordion]');

    if ($accordions.length) {
        for (let i = 0; i < $accordions.length; i++) {
            new Accordion($accordions[i]).init();
        }
    }
}

// NOTE: Slider media query
{
    const $sliders = document.querySelectorAll('[data-slider-mq]');

    if ($sliders.length) {
        $sliders.forEach($slider => {
            let swiper = null;
            const mq = window.matchMedia(`(max-width: ${$slider.dataset.sliderMq}px)`);

            const checkMq = () => {
                if (mq.matches) {
                    $slider.removeAttribute('class');
                    $slider.classList.add('swiper-container');
                    swiper = new Slider($slider);
                    swiper.init();
                } else if (swiper !== null) {
                    $slider.removeAttribute('class');
                    $slider.classList.remove('swiper-container');
                    swiper.destroy();
                    swiper = null;
                }
            };

            checkMq();
            mq.addListener(checkMq);
        });
    }
}

//HorizontalScroll
const scrollContainer = document.getElementsByClassName('horizontal-scroll')[0];

// const scrolled = e => {
//     const { scrollHeight, scrollLeft, clientHeight } = event.target;
//     console.log(scrollLeft);
//     const isBottomReached = scrollHeight - Math.round(scrollLeft) === clientHeight;

//     if (isBottomReached) {
//         console.log('end');
//     }
// };

const handleWheel = evt => {
    evt.preventDefault();
    scrollContainer.style.scrollBehavior = 'smooth';
    scrollContainer.scrollLeft += evt.deltaY * 4;
};

function onScroll(event) {
    if (event.target.scrollLeft + event.target.offsetWidth === event.target.scrollWidth) {
        scrollContainer.removeEventListener('wheel', handleWheel);

        setTimeout(() => scrollContainer?.addEventListener('wheel', handleWheel), 200);
    } else {
        scrollContainer?.addEventListener('wheel', handleWheel);
    }
}

const wheelOnMq = () => {
    if (window.matchMedia('(max-width: 1267px)').matches) {
        scrollContainer?.addEventListener('wheel', handleWheel);
    } else {
        scrollContainer?.removeEventListener('wheel', handleWheel);
    }
};

window.addEventListener('resize', wheelOnMq);

window.onload = () => {
    wheelOnMq();
};

if (scrollContainer?.scrollLeft) {
    scrollContainer?.addEventListener('wheel', handleWheel);
}
scrollContainer?.addEventListener('scroll', evt => {
    onScroll(evt);
});

let mouseDown = false;
let startX, scrollLeft;

const startDragging = function (e) {
    scrollContainer.style.scrollBehavior = 'auto';
    mouseDown = true;
    startX = e.pageX - scrollContainer.offsetLeft;
    scrollLeft = scrollContainer.scrollLeft;
};
const stopDragging = function (event) {
    mouseDown = false;
};

scrollContainer?.addEventListener('mousemove', e => {
    e.preventDefault();
    if (!mouseDown) {
        return;
    }
    const x = e.pageX - scrollContainer.offsetLeft;
    const scroll = x - startX;
    scrollContainer.scrollLeft = scrollLeft - scroll;
});

// Add the event listeners
scrollContainer?.addEventListener('mousedown', startDragging, false);
scrollContainer?.addEventListener('mouseup', stopDragging, false);
scrollContainer?.addEventListener('mouseleave', stopDragging, false);

const first = document.getElementsByClassName('reason__box--primary')[0];
const second = document.getElementsByClassName('reason__box--second')[0];
const third = document.getElementsByClassName('reason__box--third')[0];

function reveal() {
    const reveals = document.querySelectorAll('.reveal');
    for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible1 = 250;
        const elementVisible2 = 550;
        const elementVisible3 = 750;

        if (elementTop < windowHeight - elementVisible1) {
            first.classList.add('active');
            second.classList.remove('active');
            third.classList.remove('active');
        } else {
            reveals[i].classList.remove('active');
        }
        if (elementTop < windowHeight - elementVisible2) {
            first.classList.remove('active');
            second.classList.add('active');
            third.classList.remove('active');
        } else {
            reveals[i].classList.remove('active');
        }
        if (elementTop < windowHeight - elementVisible3) {
            first.classList.remove('active');
            second.classList.remove('active');
            third.classList.add('active');
        } else {
            reveals[i].classList.remove('active');
        }
    }
}

window.addEventListener('scroll', reveal);

const videoContainer = document.getElementsByClassName('video-container');
const videoBlocks = document.getElementsByClassName('video__block');
const videoMasks = document.getElementsByClassName('video-mask');
const videoClose = document.getElementsByClassName('video-close');

function toggleFullScreen(video) {
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    }
}

function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

for (let i = 0; i < videoMasks.length; i++) {
    videoBlocks[i].addEventListener('play', event => {
        videoClose[i].style.display = 'flex';
        videoMasks[i].style.display = 'none';
        videoBlocks[i].style.width = '100vw';
        videoBlocks[i].style.height = '100vh';
        videoBlocks[i].style.left = '0px';
        videoBlocks[i].style.top = '0px';
        videoBlocks[i].style.position = 'fixed';
        videoBlocks[i].style.zIndex = '9999';
        videoBlocks[i].style.borderRadius = '0px';
        videoBlocks[i].style.maxHeight = 'none';
        videoBlocks[i].style.background = 'black';

        hiddenScroll();
        toggleFullScreen(videoContainer[i]);
    });

    // if (videoContainer[i].exitFullscreen) {
    //     videoBlocks[i].pause();
    //     videoBlocks[i].style.position = 'absolute';
    //     videoBlocks[i].style.width = '100%';
    //     videoBlocks[i].style.height = '100%';
    //     videoBlocks[i].style.left = 'auto';
    //     videoBlocks[i].style.top = 'auto';
    //     videoBlocks[i].style.borderRadius = '20px';
    //     videoBlocks[i].style.zIndex = '0';
    //     videoClose[i].style.display = 'none';
    //     videoBlocks[i].style.maxHeight = '266px';
    //     videoBlocks[i].style.background = 'transparent';
    // }

    videoMasks[i]?.addEventListener('click', () => {
        videoBlocks[i].play();
    });

    videoBlocks[i]?.addEventListener('click', () => {
        if (videoBlocks[i].paused) {
            videoBlocks[i].play();
        } else {
            videoBlocks[i].pause();
        }
    });
}

for (let i = 0; i < videoBlocks.length; i++) {
    videoClose[i].addEventListener('click', () => {
        videoBlocks[i].pause();
        videoBlocks[i].style.position = 'absolute';
        videoBlocks[i].style.width = '100%';
        videoBlocks[i].style.height = '100%';
        videoBlocks[i].style.left = 'auto';
        videoBlocks[i].style.top = 'auto';
        videoBlocks[i].style.borderRadius = '20px';
        videoBlocks[i].style.zIndex = '0';
        videoClose[i].style.display = 'none';
        videoBlocks[i].style.maxHeight = '266px';
        videoBlocks[i].style.background = 'transparent';

        visibleScroll();
        closeFullscreen();
    });

    if (document.addEventListener) {
        document.addEventListener(
            'fullscreenchange',
            () => {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    visibleScroll();
                    videoBlocks[i].pause();
                }
            },
            false
        );
        document.addEventListener(
            'mozfullscreenchange',
            () => {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    visibleScroll();
                    videoBlocks[i].pause();
                }
            },
            false
        );
        document.addEventListener(
            'MSFullscreenChange',
            () => {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    visibleScroll();
                    videoBlocks[i].pause();
                }
            },
            false
        );
        document.addEventListener(
            'webkitfullscreenchange',
            () => {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    visibleScroll();
                    videoBlocks[i].pause();
                }
            },
            false
        );
    }

    videoBlocks[i].addEventListener('pause', () => {
        videoContainer[i].getElementsByClassName('video-mask')[0].style.display = 'flex';
    });
}

document.querySelectorAll('.dropdown').forEach(function (dropdownWrapper) {
    const dropdownBtn = dropdownWrapper.querySelector('.dropdown__button');
    const dropdownList = dropdownWrapper.querySelector('.dropdown__list');
    const dropdownItems = dropdownList.querySelectorAll('.dropdown__list-item');
    const dropdownInput = dropdownWrapper.querySelector('.dropdown__input_hidden');

    dropdownBtn.addEventListener('click', function () {
        dropdownList.classList.toggle('dropdown__list_visible');
        this.classList.toggle('dropdown__button_active');
    });

    dropdownItems.forEach(function (listItem) {
        listItem.addEventListener('click', function (e) {
            dropdownItems.forEach(function (el) {
                el.classList.remove('dropdown__list-item_active');
            });
            e.target.classList.add('dropdown__list-item_active');
            dropdownBtn.innerText = this.innerText;
            dropdownInput.value = this.dataset.value;
            dropdownList.classList.remove('dropdown__list_visible');
        });
    });

    document.addEventListener('click', function (e) {
        if (e.target !== dropdownBtn) {
            dropdownBtn.classList.remove('dropdown__button_active');
            dropdownList.classList.remove('dropdown__list_visible');
        }
    });

    document.addEventListener('keydown', function (e) {
        if (e.key === 'Tab' || e.key === 'Escape') {
            dropdownBtn.classList.remove('dropdown__button_active');
            dropdownList.classList.remove('dropdown__list_visible');
        }
    });
});

document.querySelectorAll('.dropdown_with-chk').forEach(function (dropdownWrapper) {
    const dropdownBtn = dropdownWrapper.querySelector('.dropdown_with-chk__button');
    const dropdownList = dropdownWrapper.querySelector('.dropdown_with-chk__list');
    const dropdownItems = dropdownList.querySelectorAll('.dropdown_with-chk__list-item');

    dropdownBtn.addEventListener('click', function () {
        dropdownList.classList.toggle('dropdown_with-chk__list_visible');
        this.classList.toggle('dropdown_with-chk__button_active');
    });

    dropdownItems.forEach(function (listItem) {
        listItem.addEventListener('click', function (e) {
            e.target.classList.toggle('dropdown_with-chk__list-item_active');
        });
    });

    document.addEventListener('click', function (e) {
        if (
            e.target !== dropdownBtn &&
            e.target !== dropdownItems &&
            !e.target.classList.contains('dropdown_with-chk__list-item') &&
            !e.target.classList.contains('dropdown_with-chk__list-item_label')
        ) {
            dropdownBtn.classList.remove('dropdown_with-chk__button_active');
            dropdownList.classList.remove('dropdown_with-chk__list_visible');
        }
    });

    document.addEventListener('keydown', function (e) {
        if (e.key === 'Tab' || e.key === 'Escape') {
            dropdownBtn.classList.remove('dropdown_with-chk__button_active');
            dropdownList.classList.remove('dropdown_with-chk__list_visible');
        }
    });
});

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
document.body.appendChild(script);

const calendlyModal = document.getElementById('calendlyModal');
const calendlyBtn = document.querySelectorAll('.calendar');
const onClickCalendly = async e => {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    body.appendChild(script);
    calendlyModal.style.display = 'block';
};
const calendlyModalContainer = new Modal(calendlyModal, calendlyBtn, onClickCalendly);

function elementInViewport2(el) {
    if (el) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        const width = el.offsetWidth;
        const height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }
        return (
            top < window.pageYOffset &&
            left < window.pageXOffset + window.innerWidth &&
            top + height > window.pageYOffset &&
            left + width > window.pageXOffset
        );
    } else {
        return null;
    }
}

const menuItem = document.getElementById('menu-main-menu');
const logo = document.getElementById('logo');
const hamburger = document.getElementsByClassName('hamburger')[0];
const logoMobile = document.getElementById('logo-mob');
const darkBox = document.getElementsByClassName('dark-section');

const getRes = () => {
    const res = [];
    for (let i = 0; i < darkBox.length; i++) {
        res.push(elementInViewport2(darkBox[i]));
    }
};

getRes();

window.addEventListener('scroll', () => {
    for (let i = 0; i < darkBox.length; i++) {
        if (elementInViewport2(darkBox[0]) || elementInViewport2(darkBox[1])) {
            for (let i = 0; i < menuItem.getElementsByTagName('li').length; i++) {
                const children = menuItem.getElementsByTagName('li')[i].getElementsByTagName('a');
                children[0].style.color = 'white';
                logo.classList.add('white');
                logo.classList.remove('black');
                logoMobile.classList.add('white');
                logoMobile.classList.remove('black');
                hamburger.classList.add('white');
            }
        } else {
            for (let i = 0; i < menuItem.getElementsByTagName('li').length; i++) {
                const children = menuItem.getElementsByTagName('li')[i].getElementsByTagName('a');
                children[0].style.color = '#3F2D54';
                logo.classList.add('black');
                logo.classList.remove('white');
                logoMobile.classList.add('black');
                logoMobile.classList.remove('white');
                hamburger.classList.remove('white');
            }
        }
    }
});

const scrollProgress = document.getElementById('scroll-progress');
const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

if (scrollProgress) {
    window.addEventListener('scroll', () => {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        scrollProgress.style.width = `${(scrollTop / height) * 100}%`;
    });
}

reveal();

// Resize function
resizer({ emitter, ms: 300 });
